import { render, staticRenderFns } from "./SolicitudesCreacionList.vue?vue&type=template&id=0b47ad16&scoped=true&"
import script from "./SolicitudesCreacionList.vue?vue&type=script&lang=js&"
export * from "./SolicitudesCreacionList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b47ad16",
  null
  
)

export default component.exports