<template>

  <div>
    <template v-if="isLoading">
      <div class="text-center">
        <b-spinner variant="primary" label="Loading" />
      </div>
    </template>
    <template v-else>

      <b-tabs align="left" lazy>

        <b-tab
            v-for="type in formTypes"
            :key="type.ftyId"
        >
          <template #title>
            <feather-icon :icon="resolveIconTab(type.ftySlug)" />
            <span class="text-capitalize">{{ type.ftyNombre }}</span>
          </template>



          <component
              :is="resolveNameComponent(type.ftySlug)"
              :uuid="type.ftyUuid"
              :id="type.ftyId"
          />

        </b-tab>

      </b-tabs>




    </template>

  </div>

</template>

<script>

import { BCardGroup, BCard, BIcon, BCardTitle, BCardText, BButton, BSpinner, BTab, BTabs } from 'bootstrap-vue'
import { mapActions } from 'vuex'

import SolicitudesCreacionList from '@/modules/solicitante/views/solicitudes/creacion/SolicitudesCreacionList.vue'
import SolicitudesModificacionList from '@/modules/solicitante/views/solicitudes/SolicitudesModificacionList.vue'

export default {
  name: 'SolicitudesMenu',

  components: {
    BCardGroup,
    BCard,
    BIcon,
    BCardTitle,
    BCardText,
    BButton,
    BSpinner,
    BTabs,
    BTab,

  },

  data(){

    return {
      isLoading: false,
      formTypes: []
    }


  },

  methods: {

    ...mapActions('sga-module', ['getFormTypes']),

    async loadData(){

      this.isLoading = true

      const data = await this.getFormTypes()

      this.formTypes = data

      this.isLoading = false

    },

    resolveNameComponent( slug ){

      const components = {
        create: SolicitudesCreacionList,
        edit: SolicitudesModificacionList,
      };
      return components[slug] || null;


    },

    resolveIconTab( slug ) {

      const icons = {
        create: 'FileIcon',
        edit: 'EditIcon',
      };

      return icons[slug] || null;

    }



  },

  async created() {

    await this.loadData()

  }

}
</script>

<style scoped>
@media (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
</style>
