import { render, staticRenderFns } from "./SolicitudesMenu.vue?vue&type=template&id=409704d5&scoped=true&"
import script from "./SolicitudesMenu.vue?vue&type=script&lang=js&"
export * from "./SolicitudesMenu.vue?vue&type=script&lang=js&"
import style0 from "./SolicitudesMenu.vue?vue&type=style&index=0&id=409704d5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "409704d5",
  null
  
)

export default component.exports