<template>
    <div>
        <template v-if="isLoading">
            <div class="text-center">
                <b-spinner variant="primary" label="Loading" />
            </div>
        </template>

        <template v-else>
            <!-- empty state: no existen datos-->
            <template v-if="isEmptyList">
                <b-card class="text-center">

                    <feather-icon
                            icon="InboxIcon"
                            size="100"
                            class="mr-50 mb-3 mt-2"
                    />
                    <h2>No hay formularios para mostrar</h2>
                    <p class="p-2">
                        Actualmente no hay formularios para mostrar. Si necesita agregar un formulario, haga clic en el botón de abajo.
                    </p>

                    <solicitud-modificacion-modal-new :form-type="id"/>


                </b-card>
            </template>
            <!-- sección de datos -->
            <template v-else>
              <solicitudes-modificacion-filters/>

                <b-card
                    no-body
                    class="mb-0"
                >
                    <!-- top -->
                    <div class="m-2">

                        <b-row>
                            <!-- per page -->
                            <b-col
                                cols="12"
                                md="6"
                                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                            >
                                <label>Mostrar</label>
                                <v-select
                                    v-model="perPage"
                                    :options="perPageOptions"
                                    :clearable="false"
                                    class="per-page-selector d-inline-block mx-50"
                                />
                                <label>registros</label>
                            </b-col>
                            <!-- search -->
                            <b-col
                                cols="12"
                                md="6"
                            >
                                <div class="d-flex align-items-center justify-content-end">

                                    <b-form-input
                                        v-model="searchQuery"
                                        class="d-inline-block mr-1"
                                        placeholder="Buscar..."
                                    />

                                    <solicitud-modificacion-modal-new :form-type="id"/>

                                </div>
                            </b-col>

                        </b-row>

                    </div>

                    <!-- table -->
                    <b-table
                        class="position-relative"
                        :items="filteredData"
                        :fields="fields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        responsive
                        show-empty
                    >
                        <!-- Column: Fecha creacion -->
                        <template #cell(frmFecha)="data">

              <span class="text-capitalize">
              {{ getFormatDate(data.value) }}
              </span>

                        </template>

                        <template #cell(frmSecuencial)="data">
                            <b-button variant="link" class="p-0" @click="handleEditFormulario(data.item.frmUuid)">
                                #{{ data.value }}
                            </b-button>
                        </template>

                        <!-- Column: Nombre colaborador -->
                        <template #cell(empNombres)="data">
                            {{ data.item.empApellidos }}, {{ data.item.empNombres }}
                        </template>

                        <!-- Column: Fecha fecha limite -->
                        <template #cell(frmFechaLimite)="data">

              <span class="text-capitalize">
              {{ getFormatDateTime(data.value) }}
              </span>

                        </template>

                        <!-- Column: Estado -->
                        <template #cell(frmEstado)="data">
                            <template v-if="data.item.frmAnulado">
                                <b-badge
                                    :id="`form-anulada-${data.item.frmId}`"
                                    pill
                                    variant="light-danger"
                                >Anulada</b-badge>
                                <b-tooltip
                                    :target="`form-anulada-${data.item.frmId}`"
                                    placement="top"
                                >
                                    <p class="mb-0">
                                        {{ data.item.frmAnulado}}
                                    </p>
                                </b-tooltip>
                            </template>
                            <template v-else>
                                <b-badge
                                    pill
                                    :variant="getLightFormStatusVariant(data.item)"
                                    class="text-capitalize"
                                >
                                    {{ getFormStatusName(data.item) }}
                                </b-badge>
                            </template>
                        </template>


                    </b-table>
                    <!-- pagination -->
                    <div class="mx-2 mb-2">
                        <b-row>
                            <b-col
                                    cols="12"
                                    sm="6"
                                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                            >
                                <span class="text-muted">{{ totalRecords }} registros encontrados</span>
                            </b-col>
                            <b-col
                                    cols="12"
                                    sm="6"
                                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                            >
                                <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalRecords"
                                        :per-page="perPage"
                                        first-number
                                        last-number
                                        class="mb-0 mt-1 mt-sm-0"
                                        prev-class="prev-item"
                                        next-class="next-item"
                                >
                                    <template #prev-text>
                                        <feather-icon
                                                icon="ChevronLeftIcon"
                                                size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                                icon="ChevronRightIcon"
                                                size="18"
                                        />
                                    </template>
                                </b-pagination>

                            </b-col>
                        </b-row>

                    </div>
                </b-card>
            </template>

        </template>

    </div>

</template>

<script>
import {BBadge, BButton, BCard, BCol, BFormInput, BPagination, BRow, BSpinner, BTable, BTooltip} from "bootstrap-vue";
import { resolvePerPageOptions, resolveFormatoFecha, resolveFormatoFechaHora, resolveFormStatusName, resolveLightFormStatusVariant } from '@/modules/common/utils'
import {mapActions, mapGetters} from "vuex";
import vSelect from "vue-select";

export default {

  name: 'SolicitudesModificacion',
    components: {
        BPagination,
        BButton,
        BTable,
        BTooltip,
        vSelect,
        BFormInput,
        BBadge,
        BRow,
        BCol,
      BCard,
      BSpinner,
      SolicitudesModificacionFilters: () => import('@/modules/solicitante/views/solicitudes/SolicitudesModificacionFilters.vue'),
      SolicitudModificacionModalNew: () => import('@/modules/solicitante/views/solicitudes/SolicitudModificacionModalNew.vue')
    },
    data(){
        return {
            isLoading: false,
            isBusy: false,

            perPage: 10,
            perPageOptions: resolvePerPageOptions(),
            currentPage: 1,
            totalRecords: 0,
            searchQuery: '',
            fields: [
                { key: 'frmSecuencial', label: 'Secuencial' },
                { key: 'frmFecha', label: 'Fecha' },
                { key: 'empNombres', label: 'Empleado' },
                { key: 'frmFechaLimite', label: 'Límite' },
                { key: 'frmEstado', label: 'Estado' },
            ]

        }
    },
    computed: {
        ...mapGetters('solicitante-solicitudes-module', ['fetchModificacionSolicitudes','fetchModificationFiltersCriteria']),

        filteredData(){
            const data =  this.fetchModificacionSolicitudes( this.searchQuery )
            this.totalRecords = data.length
            return data
        },
        // verificar que existan datos originales (antes de filtros)
        isEmptyList() {

            if( this.fetchModificationFiltersCriteria.length != 0  ) return false
            return this.fetchModificacionSolicitudes().length === 0
            //return true
        }
    },
    methods: {

        ...mapActions('solicitante-solicitudes-module', ['getModificationFormsLatestStatus']),

        async loadData(){

            try {
                this.isBusy = true
                await this.getModificationFormsLatestStatus( {  tipo: this.id  } )

            } catch (error) {

                console.log("CATCH", error)

            } finally {

                this.isBusy = false

            }

        },
        //
        getFormatDate( date ){
            return resolveFormatoFecha(date)
        },
        getFormatDateTime( date ){
            return resolveFormatoFechaHora(date)
        },

        getFormStatusName( item ){

            const status = {
                status: item.frmEstado,
                latestStatus: item.fstStatus
            }
            return resolveFormStatusName( status )

        },

        getLightFormStatusVariant( item ){
            const status = {
                status: item.frmEstado,
                latestStatus: item.fstStatus
            }
            return resolveLightFormStatusVariant(status)
        },
        // abrir/editar formulario
        handleEditFormulario(uuid){
            this.$router.push({name: 'solicitudes-modificacion-accesos', query: { key: uuid }})
        }
    },
    props: ['uuid', 'id'],

  async created() {
    await this.loadData()
  },

  mounted() {
  }
}
</script>

<style scoped>

</style>
